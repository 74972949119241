<template>
  <div class="row mt-5">
    <div class="col-1 offset-2 mt-2 text-end">
        <h5>Timeline:</h5>
    </div>
    <div class="col-2">
      <select class="form-select" v-model="timeline">
        <option value="2025">2025</option>
      </select>
    </div>
    <div class="col-4">
      <a class="btn btn-primary" @click="downloadIDPs">Download IDPs</a>
    </div>
  </div>
  <p class="mt-5">Total number of IDPs: {{ mentorshipList.length }}</p>
  <p>Total number of IDP submissions: {{ idpTotalOfUpdates }}</p>
  <table class="table">
    <thead>
      <tr>
        <th>Employee Name</th>
        <th>Employee Email</th>
        <th>Mentor Name</th>
        <th>Mentor Email</th>
        <th>Employee Update</th>
        <th>Mentor Update</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="mentorship in mentorshipList" :key="mentorship.employee_email">
        <td>{{ mentorship.employee_name }}</td>
        <td>{{ mentorship.employee_email }}</td>
        <td>
          <div v-for="mentor in mentorship.mentors" :key="mentor.mentor_email">
            {{ mentor.mentor_name }}
          </div>
        </td>
        <td>
          <div v-for="mentor in mentorship.mentors" :key="mentor.mentor_email">
            {{ mentor.mentor_email }}
          </div>
        </td>
        <td>{{ convertToDate(idpUpdateTS[mentorship.employee_email].employee_update_ts) }}</td>
        <td>{{ convertToDate(idpUpdateTS[mentorship.employee_email].mentor_update_ts) }}</td>
        <td><router-link :to="'/idp?timeline=' + timeline + '&email=' + mentorship.employee_email">Edit</router-link></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { BACKEND } from '@/api';

export default {
  name: 'AdminIDPList',
  data() {
    return {
      timeline: '2025',
      mentorshipList: [],
      idpUpdateTS: {},
    };
  },
  computed: {
    idpTotalOfUpdates() {
      let total = 0;
      for (const [,record] of Object.entries(this.idpUpdateTS)) {
        if (record.employee_update_ts) {
          total += 1;
        }
      }
      return total;
    },
  },
  methods: {
    convertToDate(timestamp) {
      if (timestamp) {
        return moment.unix(timestamp).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    downloadIDPs() {
      let endpoint = `${BACKEND}/idp/download?timeline=${this.timeline}`;
      const csvFilename = `IDPs_${this.timeline}_${moment().format("YYYY-MM-DD_HHmmss")}.csv`;
      axios.get(endpoint, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvFilename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    refreshIDPList() {
      this.mentorshipList = [];
      axios.get(`${BACKEND}/idp_mentorship/all?timeline=${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        this.mentorshipList = response.data;
      }).catch(error => {
        if (error.response.status === 401) {
          this.$store.commit('logout');
        }
      });

      this.idpUpdateTS = {};
      axios.get(`${BACKEND}/idp?timeline=${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        this.idpUpdateTS = response.data;
      }).catch(error => {
        if (error.response.status === 401) {
          this.$store.commit('logout');
        }
      });
    }
  },
  created() {
    this.refreshIDPList();
  }
}
</script>