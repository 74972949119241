<template>
  <div class="row mt-5">
    <div class="col-1 mt-2">
      <h5>Timeline:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="timeline">
        <option v-for="timeline in KPI_TIMELINES" :key="timeline" :value="timeline">
          {{ timeline }}
        </option>
      </select>
    </div>
    <div class="col-1 mt-2" v-if="subordinates.length > 0">
      <h5>Employee:</h5>
    </div>
    <div class="col-3" v-if="subordinates.length > 0">
      <select class="form-select" v-model="subordinateEmail">
        <option value="">--- Myself ---</option>
        <option v-for="subordinate in subordinates" :key="subordinate.employee_email" :value="subordinate.employee_email">
          {{ subordinate.employee_name }}
        </option>
      </select>
    </div>
    <div class="col-2" v-if="subordinates.length > 0">
      <a class="btn btn-primary" @click="downloadKPIs">
        Download KPIs
      </a>
    </div>
  </div>
  <div v-if="kpiList.length > 0">
    <div class="row mt-3">
      <b>KPI Dashboard Overview:</b>
    </div>
    <div class="row ms-3">
      <div class="col-12"><b>1. Data Update Frequency:</b> Year-to-date KPI data will be refreshed quarterly.</div>
      <div class="col-12"><b>2. Data Upload Timeline:</b></div>
      <div class="col-12 ms-3">- <b>Financial and investor relations data:</b> Available 50 days after the quarter's end.</div>
      <div class="col-12 ms-3">- <b>Other KPI data:</b> Uploaded by the first Friday after the quarter's end.</div>
      <div class="col-12"><b>3. Utilization Rate Calculation:</b></div>
      <div class="col-12 ms-3">- Utilization rate = (Billable hours / Total working hours)</div>
      <div class="col-12 ms-3">- Excludes non-chargeable projects or tasks.</div>
      <div class="col-12"><b>4. Late/Incorrect Timesheet Submission Tracking:</b> Referenced through weekly timesheet notification emails.</div>
    </div>
    <div class="row mt-3">
      <div class="col-12"><b>Rating Scale</b></div>
      <div class="col-12"><b>5</b>: Did not meet expectations</div>
      <div class="col-12"><b>4</b>: Met certain expectations</div>
      <div class="col-12"><b>3</b>: Met ALL expectations</div>
      <div class="col-12"><b>2</b>: Exceeded expectations compared to peer group</div>
      <div class="col-12"><b>1</b>: Outstanding</div>
    </div>
    <h1 class="text-center mt-2">{{ name }}</h1>
    <table class="table mt-3">
      <thead>
        <tr>
          <th scope="col">KPI</th>
          <th scope="col">Measurement</th>
          <th scope="col">Weight</th>
          <th scope="col" v-for="score in [5, 4, 3, 2, 1]" :key="score">{{ score }}</th>
          <th scope="col">Score</th>
          <th scope="col">Comment</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="kpi in kpiList" :key='kpi["kpi-measurement"]'>
          <td>{{ kpi.kpi }}</td>
          <td>{{ kpi.measurement }}</td>
          <td>{{ kpi.weight * 100 + "%" }}</td>
          <td v-for="score in [5, 4, 3, 2, 1]" :key="score">{{ kpi[score] }}</td>
          <td class="text-center">
            <span class="badge fs-6" :class='{
              "text-bg-success": isScorePositive(kpi.score),
              "text-bg-warning": !isScorePositive(kpi.score)}'>
              {{ kpi.score }}
            </span>
          </td>
          <td>{{ kpi.comment }}</td>
        </tr>
      </tbody>
    </table>
    <h3 class="mt-5 text-center">KPI Score: {{ averagedKPIScore }}</h3>
    <p class="text-center text-danger" v-if="!isScoreCompleted">KPI score has not been finalized.</p>
  </div>
  <h5 v-else-if="isEmpty" class="mt-5 text-center"><i>No data</i></h5>
  <div class="mt-5"></div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { BACKEND } from '@/api';
import { KPI_TIMELINES } from '@/config/quarter';

export default {
  name: 'KPIList',
  data() {
    return {
      KPI_TIMELINES: Object.freeze(KPI_TIMELINES),
      timeline: KPI_TIMELINES[0],
      kpiList: [],
      isEmpty: false,
      subordinates: [],
      subordinateEmail: '',
    };
  },
  computed: {
    name() {
      return this.kpiList.length > 0 ? this.kpiList[0].name : '';
    },
    averagedKPIScore() {
      let totalWScore = 0;
      let totalW = 0;
      for (const kpi of this.kpiList) {
        if (kpi.score) {
          totalWScore += parseFloat(kpi.weight) * parseFloat(kpi.score);
          totalW += parseFloat(kpi.weight);
        }
      }
      return totalW > 0 ? (totalWScore / totalW).toFixed(2) : '';
    },
    isScoreCompleted() {
      for (const kpi of this.kpiList) {
        if (!kpi.score) {
          return false;
        }
        if (kpi.kpi === "Performance Evaluation" && kpi.rawScores[this.timeline]?.length === 0) {
          return false;
        }
      }
      return true;
    }
  },
  watch: {
    timeline() {
      this.loadNameList();
      this.load();
    },
    subordinateEmail() {
      this.load();
    }
  },
  methods: {
    isScorePositive(score) {
      return score <= 3.5;
    },
    loadNameList() {
      this.subordinates = [];
      this.subordinateEmail = '';
      const [timelineYear] = this.timeline.split("/").slice(-1);
      axios.get(`${BACKEND}/idp_mentorship?timeline=${timelineYear}&mode=mentor`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.employee_name.localeCompare(b.employee_name));
          this.subordinates = response.data;
        }
      }).catch(error => {
        console.log(error.response);
      });
    },
    load() {
      this.kpiList = [];
      this.isEmpty = false;
      const emailParam = this.subordinateEmail === '' ? '' : `&email=${this.subordinateEmail}`;
      axios.get(`${BACKEND}/kpi?timeline=${this.timeline}${emailParam}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.kpi.localeCompare(b.kpi));
          response.data.sort((a, b) => b.weight.localeCompare(a.weight));
          this.kpiList = response.data;
          this.isEmpty = this.kpiList.length === 0;
        }
      }).catch(error => {
        console.log(error.response);
      });
    },
    downloadKPIs() {
      let endpoint = `${BACKEND}/kpi/download?timeline=${this.timeline}`;
      const timeStr = moment().format("YYYY-MM-DD_HHmmss");
      const csvFilename = `KPIs_${this.timeline.replace("/", "")}_${timeStr}.csv`;
      axios.get(endpoint, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvFilename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
  },
  created() {
    this.loadNameList();
    this.load();
  }
}
</script>
