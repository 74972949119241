<template>
  <h2 class="text-center mt-5">Talent Accelerator Individual Development Plan</h2>
  <div class="row mt-3 mb-3">
    <div class="col-1 mt-2">
      <h5>Timeline:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="timeline">
        <option value="2025">2025</option>
      </select>
    </div>
    <div class="col-1 mt-2">
      <h5>Version:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="idpVersion">
        <option value="">Latest</option>
        <option v-for="_date in idpHistory" :key="_date" :value="_date">{{ _date }}</option>
      </select>
    </div>
    <div class="col-1 mt-2" v-if="Object.keys(subordinates).length > 0">
      <h5>Employee:</h5>
    </div>
    <div class="col-3" v-if="Object.keys(subordinates).length > 0">
      <select class="form-select" v-model="subordinateEmail">
        <option value="">--- Myself ---</option>
        <option v-for="subordinate in subordinates" :key="subordinate.employee_email" :value="subordinate.employee_email">
          {{ subordinate.employee_name }}
        </option>
      </select>
    </div>
  </div>
  <h4 v-if="adminMode">List of Mentor(s)</h4>
  <table class="table" v-if="adminMode">
    <tbody>
      <tr v-for="mentor in mentors" :key="mentor.mentor_email">
        <th class="header col-2">Mentor's Name</th>
        <td class="col-4">{{ mentor.mentor_name }}</td>
        <th class="header col-2">Mentor's Email</th>
        <td class="col-3">{{ mentor.mentor_email }}</td>
        <td class="p-1 text-center">
          <button type="button" class="btn btn-danger btn-sm" @click="deleteMentor(mentor.mentor_email)">Delete</button>
        </td>
      </tr>
      <tr>
        <th class="header col-2">Mentor's Name</th>
        <td class="col-4 p-1"><input class="form-control p-1" v-model="newMentorName"></td>
        <th class="header col-2">Mentor's Email</th>
        <td class="col-3 p-1"><input class="form-control p-1" v-model="newMentorEmail"></td>
        <td class="p-1 text-center">
          <button type="button" class="btn btn-primary btn-sm" @click="addMentor()">Add</button>
        </td>
      </tr>
    </tbody>
  </table>
  <h4>Section 1: Information</h4>
  <table class="table">
    <tbody>
      <tr>
        <th class="header col-2">Name</th>
        <td class="col-4">{{ information.name }}</td>
        <th class="header col-2">Department</th>
        <td class="col-4">{{ information.department }}</td>
      </tr>
      <tr>
        <th class="header col-2">Position</th>
        <td>{{ information.position }}</td>
        <th class="header col-2">Mentor's Name</th>
        <td><div v-for="mentor in mentors" :key="mentor.mentor_email">{{ mentor.mentor_name }}</div></td>
      </tr>
    </tbody>
  </table>
  <h4>Section 2: KPI Targets</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th scope="col" class="text-center align-middle" rowspan="2">KPI</th>
        <th scope="col" class="text-center align-middle" rowspan="2">Measurement</th>
        <th scope="col" class="text-center align-middle" rowspan="2">Weight</th>
        <th scope="col" class="text-center" colspan="5">Rating</th>
        <th scope="col" class="text-center align-middle" rowspan="2">Score</th>
      </tr>
      <tr class="header">
        <th scope="col" class="text-center">5</th>
        <th scope="col" class="text-center">4</th>
        <th scope="col" class="text-center">3</th>
        <th scope="col" class="text-center">2</th>
        <th scope="col" class="text-center">1</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="kpi in kpiList" :key='kpi["kpi-measurement"]'>
        <td>{{ kpi.kpi }}</td>
        <td>{{ kpi.measurement }}</td>
        <td>{{ kpi.weight * 100 + "%" }}</td>
        <td v-for="score in [5, 4, 3, 2, 1]" :key="score">{{ kpi[score] }}</td>
        <td class="text-center">
          <span class="badge fs-6" :class='{
            "text-bg-success": isScorePositive(kpi.score),
            "text-bg-warning": !isScorePositive(kpi.score)}'>
            {{ kpi.score }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  <h4>Section 3: Achievement Targets</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th scope="col" colspan="4">
          What are your goals for this year?<br />
          What improvements do you plan to achieve?<br />
          What are new skills or knowledge you want to learn this year? (Choose at least 3, not over 5)
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="ach, index in achievement" :key="ach">
        <th class="header" style="width: 13%">Dimensions</th>
        <td style="width: 30%">
          <span v-if="!editable">{{ achievementDimensions[ach.dimension] }}</span>
          <select v-else class="form-select" v-model="ach.dimension">
            <option value="performance">{{ achievementDimensions["performance"] }}</option>
            <option value="feedback">{{ achievementDimensions["feedback"] }}</option>
            <option value="skill">{{ achievementDimensions["skill"] }}</option>
            <option value="2ndSkill">{{ achievementDimensions["2ndSkill"] }}</option>
            <option value="plan">{{ achievementDimensions["plan"] }}</option>
          </select>
        </td>
        <th class="header" style="width: 15%">Achievement targets</th>
        <td style="width: 38%">
          <span v-if="!editable">{{ ach.targets }}</span>
          <textarea v-else class="form-control" rows="3" v-model="ach.targets"></textarea>
        </td>
        <td class="text-center" style="width: 4%">
          <div class="mt-1" v-if="editable">
            <a href="javascript:void(0)" class="link-danger" v-on:click="deleteAchievement(index)">Del</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <button v-if="editable" type="button" class="btn btn-secondary mb-3" @click="addAchievement">Add a New Row</button>
  <h4>Section 4: Career Aspirations</h4>
  <table class="table mb-0">
    <tbody>
      <tr>
        <th class="header col-4">Aspirations Short-Term (1-2Y)</th>
        <td>
          <span v-if="!editable">{{ career.short_term }}</span>
          <textarea v-else class="form-control" rows="3" v-model="career.short_term"></textarea>
        </td>
      </tr>
      <tr>
        <th class="header col-4">Aspirations Long-Term (3-5Y)</th>
        <td>
          <span v-if="!editable">{{ career.long_term }}</span>
          <textarea v-else class="form-control" rows="3" v-model="career.long_term"></textarea>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table">
    <tbody>
      <tr>
        <th class="header col-6">Are you interested in career promoiton opportunities within Bluebik</th>
        <td>
          <span v-if="!editable">{{ career.promotion }}</span>
          <select v-else class="form-select" v-model="career.promotion">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </td>
      </tr>
      <tr v-if="isInterestedInPromotion">
        <th class="header col-6">What positions are you interested in</th>
        <td>
          <span v-if="!editable">{{ career.position }}</span>
          <input v-else class="form-control" v-model="career.position">
        </td>
      </tr>
      <tr v-if="isInterestedInPromotion">
        <th class="header col-6">Key development areas for growth to promoiton</th>
        <td>
          <span v-if="!editable">{{ career.development }}</span>
          <input v-else class="form-control" v-model="career.development">
        </td>
      </tr>
      <tr v-if="isInterestedInPromotion">
        <th class="header col-6">Would you be open to relocation if a suitable position became available</th>
        <td>
          <span v-if="!editable">{{ career.relocation }}</span>
          <select v-else class="form-select" v-model="career.relocation">
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>
  <h4>Section 5: Action Plan</h4>
  <table class="table table-fixed">
    <thead>
      <tr class="header">
        <th scope="col" class="text-center">Area of Improvement (skills)</th>
        <th scope="col" class="text-center">Desired Outcome</th>
        <th scope="col" class="text-center">Progress Update</th>
        <th scope="col" class="text-center">Timeline</th>
        <th scope="col" class="text-center">Status</th>
        <th style="width: 4%"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="dev, index in development" :key="dev">
        <td>
          <span v-if="!editable">{{ dev.area }}</span>
          <textarea v-else class="form-control" rows="3" v-model="dev.area"></textarea>
        </td>
        <td>
          <span v-if="!editable">{{ dev.outcome }}</span>
          <textarea v-else class="form-control" rows="3" v-model="dev.outcome"></textarea>
        </td>
        <td>
          <span v-if="!editable">{{ dev.progress }}</span>
          <textarea v-else class="form-control" rows="3" v-model="dev.progress"></textarea>
        </td>
        <td>
          <span v-if="!editable">{{ dev.timeline }}</span>
          <input v-else class="form-control" type="date" v-model="dev.timeline">
        </td>
        <td>
          <span v-if="!editable">{{ dev.status }}</span>
          <select v-else class="form-select" v-model="dev.status">
            <option value="Not Start">Not Start</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="Continue">Continue</option>
          </select>
        </td>
        <td class="text-center">
          <div class="mt-1" v-if="editable">
            <a href="javascript:void(0)" class="link-danger" v-on:click="deleteDevelopment(index)">Del</a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <button v-if="editable" type="button" class="btn btn-secondary mb-3" @click="addDevelopment">Add a New Row</button>
  <h4>Section 6: Performance Evaluaiton</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th scope="col" class="text-center col-2">Period</th>
        <th scope="col" class="text-center col-3">Performance Manager</th>
        <th scope="col" class="text-center col-1">Score</th>
        <th scope="col" class="text-center">Comment</th>
        <th scope="col" class="text-center col-1">View</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="period in performancePeriods" :key="period">
        <tr v-for="form in performanceEval[period]" :key="form.id">
          <td class="text-center">{{ period }}</td>
          <td>{{ form.evaluator_name }}</td>
          <td class="text-center">
            <span v-if="form.approved" class="badge fs-6" :class='{
              "text-bg-success": isScorePositive(form.approved.at(-1).scores[0]),
              "text-bg-warning": !isScorePositive(form.approved.at(-1).scores[0])}'>
              {{ form.approved.at(-1).scores[0] }}
            </span>
          </td>
          <td>
            <span v-if="form.approved">{{ form.approved.at(-1).comment }}</span>
          </td>
          <td class="text-center"><router-link v-if="form.approved" :to="'/forms/' + form.id">View</router-link></td>
        </tr>
      </template>
    </tbody>
  </table>
  <h4>Section 7: Mentor's Comment</h4>
  <table class="table">
    <thead>
      <tr class="header">
        <th class="text-center col-2">Period</th>
        <th class="text-center col-3">Does the employee achieve<br/>the IDP for this period?</th>
        <th class="text-center">Mentor's Comment</th>
        <th class="text-center col-3">Mentor's Name</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="period in mentorPeriods" :key="period">
        <tr v-for="content, mentorEmail in mentorComment" :key="mentorEmail">
          <td class="text-center">{{ period }}</td>
          <td class="text-center">
            <select v-if="canComment(mentorEmail)" class="form-select" v-model="content[period].achieve">
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            <span v-else>{{ content[period].achieve }}</span>
          </td>
          <td>
            <textarea v-if="canComment(mentorEmail)" class="form-control" rows="3" v-model="content[period].comment"></textarea>
            <span v-else>{{ content[period].comment }}</span>
          </td>
          <td>{{ content.name }}</td>
        </tr>
      </template>
    </tbody>
  </table>
  <div class="text-center mb-5">
    <button v-if="latestVersion && !adminMode" type="button" class="btn btn-primary" @click="save(true)">Submit</button>
    <button v-if="latestVersion && !adminMode" type="button" class="btn btn-secondary ms-2" @click="save(false)">Save draft</button>
  </div>
</template>

<style scoped>
.header {
  background-color: #DEEBF7;
}
</style>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';
import { IDP_PERF_TIMELINES } from '@/config/quarter';
const MAX_ACHIEVEMENT = 5;
const MENTOR_PERIODS = ["H1", "H2"];
const ACHIEVEMENT_DIMENSIONS = {
  "performance": "Performance improvement",
  "feedback": "Development area from previous feedback",
  "skill": "Current skill advancement",
  "2ndSkill": "2nd skill development",
  "plan": "What do you plan to do differently?"
}

export default {
  name: 'IDPItem',
  data() {
    return {
      timeline: '2025',
      kpiTimeline: 'Q1/2025',
      subordinates: {},
      subordinateEmail: '',
      mentors: {},
      information: {},
      kpiList: [],
      achievement: [],
      career: {},
      development: [],
      performanceEval: {},
      mentorComment: {},
      idpHistory: [],
      idpVersion: '',
      adminMode: false,
      newMentorName: '',
      newMentorEmail: '',
    };
  },
  computed: {
    isInterestedInPromotion() {
      return this.career.promotion === "Yes";
    },
    performancePeriods() {
      return IDP_PERF_TIMELINES;
    },
    achievementDimensions() {
      return ACHIEVEMENT_DIMENSIONS;
    },
    mentorPeriods() {
      return MENTOR_PERIODS.map(x => x + '/' + this.timeline);
    },
    mentorMode() {
      return this.subordinateEmail !== '';
    },
    latestVersion() {
      return this.idpVersion === '';
    },
    editable() {
      return !this.mentorMode && this.latestVersion && !this.adminMode;
    }
  },
  watch: {
    timeline() {
      this.loadSubordinateList();
      this.load();
      this.loadHistory();
    },
    subordinateEmail() {
      this.load();
      this.loadHistory();
    },
    idpVersion() {
      if (this.idpVersion === "") {
        this.loadContent();
      } else {
        this.loadVersion();
      }
    }
  },
  methods: {
    isScorePositive(score) {
      return score <= 3.5;
    },
    addAchievement() {
      if (this.achievement.length < MAX_ACHIEVEMENT) {
        this.achievement.push({});
      }
    },
    deleteAchievement(index) {
      if (confirm("Do you want to delete this row of achievement target?")) {
        this.achievement.splice(index, 1);
      }
    },
    addDevelopment() {
      this.development.push({});
    },
    deleteDevelopment(index) {
      if (confirm("Do you want to delete this row of action plan?")) {
        this.development.splice(index, 1);
      }
    },
    canComment(mentorEmail) {
      return this.mentorMode && this.latestVersion && mentorEmail === this.$store.getters.email && !this.adminMode;
    },
    deleteMentor(mentorEmail) {
      if (!confirm(`Do you want to delete ${mentorEmail}?`)) {
        return;
      }
      axios.delete(`${BACKEND}/idp_mentorship/${this.timeline}/${mentorEmail},${this.subordinateEmail}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          alert(response.data.message);
          this.load();
        }
      }).catch(error => {
        if (error.response.status === 401) {
          this.$store.commit('logout');
        } else {
          alert(error.response.data.message);
        }
      });
    },
    addMentor() {
      axios.post(`${BACKEND}/idp_mentorship`, {
        timeline: this.timeline,
        mentor_name: this.newMentorName,
        mentor_email: this.newMentorEmail,
        employee_name: this.information.name,
        employee_email: this.subordinateEmail,
      }, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          alert(response.data.message);
          this.newMentorName = "";
          this.newMentorEmail = "";
          this.load();
        }
      }).catch(error => {
        if (error.response.status === 401) {
          this.$store.commit('logout');
        } else {
          alert(error.response.data.message);
        }
      });
    },
    loadSubordinateList() {
      this.subordinates = {};
      this.subordinateEmail = '';
      axios.get(`${BACKEND}/idp_mentorship?timeline=${this.timeline}&mode=mentor`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          for (const mentorship of response.data) {
            this.subordinates[mentorship.employee_email] = mentorship;
          }
        }
      }).catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          this.$store.commit('logout');
        }
      });
    },
    load() {
      this.mentors = {};
      const emailParam = this.subordinateEmail === '' ? '' : `&email=${this.subordinateEmail}`;
      axios.get(`${BACKEND}/idp_mentorship?timeline=${this.timeline}${emailParam}&mode=employee`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          for (const mentorship of response.data) {
            this.mentors[mentorship.mentor_email] = mentorship;
          }
          this.loadContent();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    loadContent() {
      this.information = {};
      this.kpiList = [];
      this.achievement = [];
      this.career = {};
      this.development = [];
      this.performanceEval = {};
      this.mentorComment = {};
      this.idpVersion = '';

      // IDP
      const idpEmail = this.subordinateEmail === '' ? this.$store.getters.email : this.subordinateEmail;
      axios.get(`${BACKEND}/idp/${this.timeline}/${idpEmail}/`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.information.name = response.data.evaluatee_name;
          this.information.department = response.data.evaluatee_bu;
          this.information.position = response.data.evaluatee_position;

          if (response.data.career) {
            this.career = response.data.career;
          }

          if (response.data.achievement) {
            this.achievement = response.data.achievement;
          }
          if (!this.mentorMode && this.achievement.length < MAX_ACHIEVEMENT) {
            this.achievement.push({});
          }

          if (response.data.development) {
            this.development = response.data.development;
          }
          if (!this.mentorMode) {
            this.development.push({});
          }

          if (response.data.mentor_comment) {
            this.mentorComment = response.data.mentor_comment;
          }
          for (const [mentorEmail, mentor] of Object.entries(this.mentors)) {
            if (!this.mentorComment[mentorEmail]) {
              this.mentorComment[mentorEmail] = {};
              this.mentorComment[mentorEmail].name = mentor.mentor_name;
            }
          }
          for (const [, content] of Object.entries(this.mentorComment)) {
            for (const period of this.mentorPeriods) {
              if (!content[period]) {
                content[period] = {};
              }
            }
          }
        }
      }).catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          this.$store.commit('logout');
        }
      });

      // KPI
      const emailParam = this.subordinateEmail === '' ? '' : `&email=${this.subordinateEmail}`;
      axios.get(`${BACKEND}/kpi?timeline=${this.kpiTimeline}${emailParam}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.kpi.localeCompare(b.kpi));
          response.data.sort((a, b) => b.weight.localeCompare(a.weight));
          this.kpiList = response.data;
        }
      }).catch(error => {
        console.log(error);
      });

      // Performance Evaluation
      const perfEmailParam = this.subordinateEmail === '' ? '&email=' : `&email=${this.subordinateEmail}`;
      for (const timeline of IDP_PERF_TIMELINES) {
        axios.get(`${BACKEND}/forms?timeline=${timeline}${perfEmailParam}`, {
          headers: {
            'Authorization': this.$store.getters.idToken
          }
        }).then((response) => {
          if (response.status === 200) {
            this.performanceEval[timeline] = response.data;
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    loadHistory() {
      this.idpHistory = [];
      const emailParam = this.subordinateEmail === '' ? '' : `&email=${this.subordinateEmail}`;

      axios.get(`${BACKEND}/idp_history?timeline=${this.timeline}${emailParam}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => b.update_date.localeCompare(a.update_date));
          this.idpHistory = response.data.map(x => x.update_date);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    loadVersion() {
      this.achievement = [];
      this.career = {};
      this.development = [];
      this.mentorComment = {};
      const emailParam = this.subordinateEmail === '' ? '' : `&email=${this.subordinateEmail}`;

      axios.get(`${BACKEND}/idp_history/${this.idpVersion}?timeline=${this.timeline}${emailParam}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          if (response.data.career) {
            this.career = response.data.career;
          }

          if (response.data.achievement) {
            this.achievement = response.data.achievement;
          }

          if (response.data.development) {
            this.development = response.data.development;
          }

          if (response.data.mentor_comment) {
            this.mentorComment = response.data.mentor_comment;
          }
        }
      }).catch(error => {
        console.log(error);
      });
    },
    save(isSubmitted) {
      if (isSubmitted) {
        if (!confirm("Do you want to submit this IDP?")) {
          return;
        }
      }
      const idpData = {
        timeline: this.timeline,
        achievement: this.achievement,
        career: this.career,
        development: this.development,
        mentor_comment: this.mentorComment,
        is_submitted: isSubmitted
      };
      if (this.subordinateEmail !== '') {
        idpData["email"] = this.subordinateEmail;
      }
      axios.patch(`${BACKEND}/idp`, idpData, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          alert("Data has been saved.")
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
  created() {
    if (this.$route.query.timeline) {
      this.timeline = this.$route.query.timeline;
    }
    if (this.$route.query.email) {
      this.subordinateEmail = this.$route.query.email;
      this.adminMode = true;
    } else {
      this.loadSubordinateList();
    }
    this.load();
    this.loadHistory();
  }
}
</script>
